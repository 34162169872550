










































import { useApi } from '@/composables/useApi';
import { useLoader } from '@/composables/useLoader';
import { useFormValidation } from '@/composables/useValidation';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, reactive, ref } from '@/plugins/composition';
import { useRouter } from '@/router/useRouter';
import { rules } from '@/composables/useValidation/validations';
import { mdiEyeOutline } from '@mdi/js';
import BaseButton from '@/components/base/BaseButton.vue';
import { routeNames } from '@/router/routeNames';
import { useStatus } from '@/composables/useStatus';

export default defineComponent({
  components: { MemberPage, BaseButton },
  name: 'ResetPasswordPage',
  props: {
    token: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    console.log('---------------- ResetPassword Setup -------------');
    const success = ref(false);
    const valid = ref(false);
    const isPasswordVisible = ref(false);
    const { loading, withLoader } = useLoader();
    const { post } = useApi();
    const { router } = useRouter();
    const { setMessage } = useStatus();

    const formData = reactive({
      password: '',
      email: props.email,
    });

    const { errors, mapErrors } = useFormValidation(formData);

    const login = async () =>
      withLoader(async () => {
        loading.value = true;
        const reset = await post('/reset-password', {
          email: formData.email,
          password: formData.password,
          token: props.token,
        }).catch((error) => {
          mapErrors(error);
        });

        if (reset) {
          success.value = true;
        }
      });

    return {
      success,
      valid,
      isPasswordVisible,
      login,
      loading,
      formData,
      errors,
      rules,
      icons: {
        mdiEyeOutline,
      },
      routeNames,
    };
  },
});
